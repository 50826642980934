<template>
  <v-container fluid>
    <v-snackbar :value="isError" color="error">{{ isError ? getErrorMessage : 'Error inesperado' }}</v-snackbar>
    <v-snackbar :value="infoSnackbar" color="success">
      {{ infoSnackbar ? infoSnackbarMessage : '' }}
      <v-btn color="white" text @click="infoSnackbar = false">Cerrar</v-btn>
    </v-snackbar>
    <v-dialog v-model="showCrearAnotacionDialog">
      <v-card>
        <v-card-title>
          <span class="headline">Crear Anotación</span>
        </v-card-title>
        <v-card-text class="pb-0">
          <v-container fluid>
            <v-textarea
              label="Escribe aquí..."
              outlined
              auto-grow
              :counter="250"
              class="amber lighten-4"
              v-model="textoAnotacion"
            ></v-textarea>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn text @click="showCrearAnotacionDialog = false">Cancelar</v-btn>
          <v-spacer></v-spacer>
          <v-btn text @click="crearAnotacion" color="success">Crear</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-layout class="d-flex flex-column">
      <v-row>
        <v-col cols="12" sm="12" class="d-flex justify-space-between">
          <p class="display-1 font-weight-light">Anotaciones</p>
          <v-btn dark color="success" @click="showCrearAnotacionDialog = true">
            <v-icon left>mdi-pencil</v-icon>Crear
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12">
          <div v-if="getListaAnotaciones.length">
            <v-card
              style="width: 100%"
              class="mb-2 amber lighten-4"
              v-for="(anotacion) in getListaAnotaciones"
              :key="anotacion.idAnotacion"
            >
              <v-card-title class="body-2 d-flex justify-end">
                <p class="font-weight-regular my-0">
                  <v-icon left small>mdi-clock-outline</v-icon>
                  <span class="font-weight-light">Creado el</span>
                  {{formatDateCard(anotacion.fechaAnotacion)}} - {{$moment(parseInt(anotacion.fechaAnotacion)).fromNow()}}
                </p>
              </v-card-title>
              <v-card-text class="display-1">{{anotacion.textoAnotacion}}</v-card-text>
              <v-card-actions class="body-2">
                <p class="font-weight-medium my-0 mx-2">
                  <v-icon left>mdi-account-circle</v-icon>
                  <span class="font-weight-light">Creado Por</span>
                  {{anotacion.nombreTrabajador}}
                </p>
                <v-spacer></v-spacer>
                <v-btn
                  icon
                  text
                  color="error"
                  large
                  @click="eliminarAnotacion(anotacion.idAnotacion)"
                  :disabled="isLoading"
                  v-if="getInfoUsuario.rol != 'Usuario'"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </div>
          <div v-else class="d-flex justify-center text-center headline font-weight-light">
            <p>
              No tienes anotaciones, intenta agregar alguno con el botón de
              <span
                class="green--text"
              >CREAR</span>
            </p>
          </div>
        </v-col>
      </v-row>
    </v-layout>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      headers: [
        {
          text: "Anotación",
          value: "textoAnotacion",
          sortable: false,
          width: "50%"
        },
        {
          text: "Creado Por",
          value: "nombreTrabajador",
          align: "center"
        },
        {
          text: "Fecha de Creación",
          value: "fechaAnotacion",
          align: "center"
        },
        {
          text: "Acciones",
          value: "actions",
          align: "center",
          sortable: false
        }
      ],

      anotaciones: [],
      showCrearAnotacionDialog: false,
      textoAnotacion: "",
      infoSnackbar: false,
      infoSnackbarMessage: ""
    };
  },

  methods: {
    cleanSource(source) {
      return JSON.parse(JSON.stringify(source));
    },

    async crearAnotacion() {
      let body = this.cleanSource({ textoAnotacion: this.textoAnotacion });

      await this.postNuevaAnotacion(body);
      await this.fetchAnotaciones();
      this.infoSnackbar = true;
      this.infoSnackbarMessage = "Se ha creado una anotación";
      this.textoAnotacion = "";
      this.showCrearAnotacionDialog = false;
    },

    formatDateCard(date) {
      let parsedDate = new Date(parseInt(date));
      let options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit"
      };
      return `${parsedDate.toLocaleDateString(
        "es-PE",
        options
      )} ${parsedDate.toLocaleTimeString()}`;
    },

    async eliminarAnotacion(idAnotacion) {
      await this.deleteAnotacion(idAnotacion);
      await this.fetchAnotaciones();
    },

    ...mapActions("trabajador", [
      "fetchAnotaciones",
      "postNuevaAnotacion",
      "deleteAnotacion"
    ])
  },

  computed: {
    ...mapGetters("trabajador", ["getListaAnotaciones"]),
    ...mapGetters("user", [
      "isError",
      "getError",
      "isLoading",
      "getInfoUsuario"
    ])
  },

  async mounted() {
    await this.fetchAnotaciones();
  }
};
</script>

<style>
</style>